<template>
  <div class="" style="width: 100%;height: 100%;">
    <el-row style="margin-bottom: 10px;" class="flexBetween flex_1 ">
      <div class="flex_1">
        <el-input v-model="searchForm.mohuTitle" size="small" clearable style="width: 200px!important;" placeholder="模糊搜索">
          <el-button slot="append" size="small" icon="el-icon-search" @click="getList"></el-button>
        </el-input>
      </div>
    </el-row>
    <el-table :data="DataList" height="calc( 100% - 100px)" style="width: 100%;" stripe border>
      <el-table-column label="操作" width="60" fixed="left">
        <template slot-scope="scope">
          <el-tag size="mini" style="cursor: pointer;" @click="chooseSG(scope.row)">选择</el-tag>

        </template>
      </el-table-column>

      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="sg_num" label="申购单号" width="120" :show-overflow-tooltip="true" />
      <el-table-column prop="title" label="申购项目描述" width="200" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">
            {{ scope.row.dept_name }}{{ scope.row.username }}{{ scope.row.buy_type }}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="cdate" label="申购时间" min-width="120">
        <template slot-scope="scope">
          <span style="font-size: 12px;">{{ scope.row.cdate ? scope.row.cdate.substr(0, 10) : '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="申购原因" width="160" />
      <el-table-column prop="sp_status" label="状态" width="100" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="scope.row.sp_status == 0" style="color: #409eff">待审核</span>
          <span v-if="scope.row.sp_status == 1" style="color: #67c23a">审核通过</span>
          <span v-if="scope.row.sp_status == 2" style="color: #F56C6C">审核驳回</span>
        </template>
      </el-table-column>

    </el-table>


    <el-pagination class="cus_page"
                   @size-change="handleSizeChange"
                   @current-change="paginate"
                   :current-page.sync="page.current_page"
                   :page-sizes="[10, 50, 100]"
                   :page-size="page.per_page"
                   layout="total,sizes, prev, pager, next"
                   :total="page.count">
    </el-pagination>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      searchForm: {},
      DataList: [],
      multipleSelection: [],
      queryForm: {},
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      dialogTitle: '新增耗材',
      dialogVisible: false,
      EditItem: {},
      disabled: false,
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let _this = this
      let params = {}, arr = []
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      params.sfrk = '0'
      params.datatype = 'myrkd'
      params.sp_status = '1'
      this.$http.post("/api/sx_sg_list", params).then(res => {
        this.DataList = res.data.data;
        if(res.data&&res.data.page){
          this.page = res.data.page
          this.page_hc = res.data.page
        }

      })
    },

    onSearch() {
      this.page.current_page = 1
      this.getList()
    },
    chooseSG(row){
      this.$$parent(this,'chooseSG',row)
    },

    showDetails(row) {
      let _this = this
      this.editItem(row, true)
      this.dialogTitle = '耗材详情'
      this.dialogVisible = true
      this.disabled = true
    },

    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.per_page = val
      this.getList();
    },
    paginate(val) {
      this.page.current_page = val
      this.getList();
    },
  }
}
</script>

<style lang="less" type="text/scss">



.pagezclist {
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .labelCss {
    .el-form-item__label {
      color: #909399 !important;
      font-size: 12px;
    }
  }

  .el-input, .el-select, .el-cascader {
    width: 100% !important;
  }

  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF !important;
      background-color: #Ecf5ff !important;
      border-color: #b3d8ff !important;
    }
  }

  .el-divider {
    margin-top: 0;
  }

  .el-drawer__wrapper {
    top: 70px;
  }

  .drawer_box, .targetUsers {
    padding: 10px;

    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .updimage {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
    border: 1px dotted #ccc;
    background-size: 100% auto;
    background-position: center;
    overflow: hidden;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .cus_page {
    margin-top: 10px;

    .el-pagination__sizes {
      width: 130px;
    }
  }
}



</style>
